.button {
  background-color: #0077D7;
  color: white;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #0077d7c4;
  }

  svg, img {
    margin-right: 10px;
  }

  &.disabled {
    background-color: #878787;
    pointer-events: none;
  }
}