.non-standard-tickets-table-row {
  display: flex;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &.headers {
    
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .column {
    width: 100px;
    margin: 0 5px;

    input {
      width: 100%;
    }
  }

}