.react-calendar {
  width: 700px;
  margin-right: auto;
  margin-left: auto;

  .react-calendar__tile {
    &.disabled {
      background-color: #e6e6e6;
      pointer-events: none; 
    }
    &:hover {
      background-color: #c3e2f9;
    }
  }

  .react-calendar__tile--active.react-calendar__tile--now {
    background-color: #006edc;
    color: white;
  }
  
}