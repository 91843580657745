.productivity-create-record {
  padding: 40px;  

  .productivity-create-record__page-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 35px;
  }


  .productivity-create-record__input-fields {
    .input-fields__row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .row__label {
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  
}