.option-pill {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  padding: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    margin-right: 8px;
  }

  .icon-wrapper {
    width: 10px;
    
    .plus-icon-svg {
      width: 10px;
      height: 10px;
    }
  
    .x-icon-svg {
      width: 8px;
      height: 8px;
    }
  }

  

  &:hover {
    color: #0077D7;
    
    .plus-icon-svg {
      path {
        stroke: #0077d7;
      }
    }
  }

  &.selected {
    background-color: #C3E2F9;
    color: #0077d7;

    .plus-icon-svg {
      stroke: #0077D7;
    }
  }
  
}