.schedule-input-table {

  .schedule-input-table__back-button {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;

    svg {
      position: relative;
      height: 9px;
      width: 9px;
      margin-right: 7px;
      right: 0;
      transition: right .5s;
    }

    &:hover {
      svg {
        right: 7px;
      }
    }

  }

  .schedule-input-table__store-index {
    margin-top: 50px;

    .store-index__headers {
      margin-left: 20px;
      display: flex;

      > div {
        width: 200px;
      }
    }

    .store-index__store-row {
      .store-row__name {

      }

      .schedule-input-table-row {
        margin-left: 20px;

        > div {
          padding: 0 4px;
        }
      }
    }
  }

  .schedule-input-table__save-edits {
    float: right;
    position: relative;
    top: 60px;
  }
}