.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
  display: block;

  .modal__mask {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    background-color: #141414;
    opacity: 0.6;
  }

  .modal__custom-modal-content {
    position: absolute;
    z-index: 3;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    background-color: #FFFFFF;
    border-radius: 3px;
  }
}