.table-row {
  display: flex;

  > div {
    width: 200px;
  }

  &.loading {
    svg {
      height: 22px;
      width: 22px;
    }
  }
}