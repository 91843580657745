.action-response-modal {
  width: 500px;
  height: 225px;
  padding: 40px 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 16px;
  }
}
