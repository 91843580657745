.inventory-management {
  .options {
    display: flex;

    .calendar-icon {
      cursor: pointer;
      margin-left: 8px;
    }

    select {
      margin: 0 15px;
    }

  }

  .inventory-row {
    width: 80%;
    display: flex;
    

    .headers {

    }

    .column {
      &.header {

      }


      &.ingredient {
        width: 400px;
      }

      &.var {
        width: 400px;
      }

      &.var-waste {
        width: 400px;
      }

      &.unit {
        width: 400px;
      }
    }

  }

  .button {
    margin-top: 40px;
  }

  .loading {
    display: flex;
    align-items: center;
  }
}