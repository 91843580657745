body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.App {
  margin-top: 15px;
  width: 1400px;
}

.App .navbar-brand {
  font-weight: bold;
}
.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Home .actions .header {
  font-size: 18px;
  margin-bottom: 20px;
}

.Home .actions .actions-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.Home .actions .actions-list .button {
  margin-right: 15px;
}

.Home .actions .actions-list .button:last-child {
  margin-right: 0px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
  display: block; }
  .modal .modal__mask {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    background-color: #141414;
    opacity: 0.6; }
  .modal .modal__custom-modal-content {
    position: absolute;
    z-index: 3;
    margin: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);
    background-color: #FFFFFF;
    border-radius: 3px; }

.waiting-for-response {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .waiting-for-response .header {
    font-size: 22px;
    margin-bottom: 10px; }

.skyvia-check .inputs-row {
  margin-top: 20px;
  height: 30px; }
  .skyvia-check .inputs-row .input {
    height: 100%;
    margin-right: 15px;
    border: 2px solid #c4c4c4;
    border-radius: 3px; }
    .skyvia-check .inputs-row .input--error {
      border-color: red; }

.mark-complete-finished {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .mark-complete-finished .text {
    margin-bottom: 15px; }

.action-response-modal {
  width: 500px;
  height: 225px;
  padding: 40px 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .action-response-modal .text {
    font-size: 16px; }

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@media all and (min-width: 480px) {
    .NewBiEntry {
      padding: 60px 0;
    }
  
    .NewBiEntry form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
.react-calendar {
  width: 700px;
  margin-right: auto;
  margin-left: auto; }
  .react-calendar .react-calendar__tile.disabled {
    background-color: #e6e6e6;
    pointer-events: none; }
  .react-calendar .react-calendar__tile:hover {
    background-color: #c3e2f9; }
  .react-calendar .react-calendar__tile--active.react-calendar__tile--now {
    background-color: #006edc;
    color: white; }

.button {
  background-color: #0077D7;
  color: white;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  .button:hover {
    background-color: #0077d7c4; }
  .button svg, .button img {
    margin-right: 10px; }
  .button.disabled {
    background-color: #878787;
    pointer-events: none; }

.schedule-input-table .schedule-input-table__back-button {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700; }
  .schedule-input-table .schedule-input-table__back-button svg {
    position: relative;
    height: 9px;
    width: 9px;
    margin-right: 7px;
    right: 0;
    transition: right .5s; }
  .schedule-input-table .schedule-input-table__back-button:hover svg {
    right: 7px; }

.schedule-input-table .schedule-input-table__store-index {
  margin-top: 50px; }
  .schedule-input-table .schedule-input-table__store-index .store-index__headers {
    margin-left: 20px;
    display: -webkit-flex;
    display: flex; }
    .schedule-input-table .schedule-input-table__store-index .store-index__headers > div {
      width: 200px; }
  .schedule-input-table .schedule-input-table__store-index .store-index__store-row .schedule-input-table-row {
    margin-left: 20px; }
    .schedule-input-table .schedule-input-table__store-index .store-index__store-row .schedule-input-table-row > div {
      padding: 0 4px; }

.schedule-input-table .schedule-input-table__save-edits {
  float: right;
  position: relative;
  top: 60px; }

.schedule-input-table-row {
  display: -webkit-flex;
  display: flex; }
  .schedule-input-table-row > div {
    width: 200px; }
  .schedule-input-table-row.loading svg {
    height: 22px;
    width: 22px; }

.daily-bi-table th, .daily-bi-table td {
  padding-right: 25px; }

.daily-bi-table .daily-bi-table__back-button {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700; }
  .daily-bi-table .daily-bi-table__back-button svg {
    position: relative;
    height: 9px;
    width: 9px;
    margin-right: 7px;
    right: 0;
    transition: right .5s; }
  .daily-bi-table .daily-bi-table__back-button:hover svg {
    right: 7px; }

.daily-bi-table .daily-bi-table__store-index {
  margin-top: 50px; }
  .daily-bi-table .daily-bi-table__store-index .store-index__headers {
    display: -webkit-flex;
    display: flex; }
    .daily-bi-table .daily-bi-table__store-index .store-index__headers > div {
      width: 200px; }

.daily-bi-table .daily-bi-table__save-edits {
  float: right;
  position: relative;
  top: 60px; }


.productivity-row {
  display: -webkit-flex;
  display: flex; }
  .productivity-row > * {
    width: 200px; }

.productivity-create-record {
  padding: 40px; }
  .productivity-create-record .productivity-create-record__page-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 35px; }
  .productivity-create-record .productivity-create-record__input-fields .input-fields__row {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px; }
    .productivity-create-record .productivity-create-record__input-fields .input-fields__row .row__label {
      font-weight: bold;
      font-size: 14px;
      margin-right: 10px; }


.page-header {
  font-size: 32px; }

.inventory-management .options {
  display: -webkit-flex;
  display: flex; }
  .inventory-management .options .calendar-icon {
    cursor: pointer;
    margin-left: 8px; }
  .inventory-management .options select {
    margin: 0 15px; }

.inventory-management .inventory-row {
  width: 80%;
  display: -webkit-flex;
  display: flex; }
  .inventory-management .inventory-row .column.ingredient {
    width: 400px; }
  .inventory-management .inventory-row .column.var {
    width: 400px; }
  .inventory-management .inventory-row .column.var-waste {
    width: 400px; }
  .inventory-management .inventory-row .column.unit {
    width: 400px; }

.inventory-management .button {
  margin-top: 40px; }

.inventory-management .loading {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

@media all and (min-width: 480px) {
    .BlueLineCsvUpload {
      padding: 60px 0;
    }
  
    .BlueLineCsvUpload form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  

.non-standard-ticket-management {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .non-standard-ticket-management .table-header {
    border-bottom: 2px solid #c7c7c7;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative; }
    .non-standard-ticket-management .table-header .option-section, .non-standard-ticket-management .table-header .dates, .non-standard-ticket-management .table-header .search-section {
      margin-bottom: 5px; }
    .non-standard-ticket-management .table-header .option-section .option-pills {
      display: -webkit-flex;
      display: flex;
      width: 600px;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .non-standard-ticket-management .table-header .option-section .option-pills .option-pill {
        margin: 3px 5px; }
    .non-standard-ticket-management .table-header .dates {
      display: -webkit-flex;
      display: flex; }
    .non-standard-ticket-management .table-header .search-section .search-terms {
      display: -webkit-flex;
      display: flex;
      width: 600px;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .non-standard-ticket-management .table-header .search-section .search-terms .option-pill {
        margin: 3px 5px; }
    .non-standard-ticket-management .table-header .button {
      position: absolute;
      right: 50px;
      bottom: 15px; }
  .non-standard-ticket-management .table-information {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    overflow: auto;
    margin-bottom: 25px; }

.non-standard-tickets-table-headers .column {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  color: #888888; }
  .non-standard-tickets-table-headers .column .sort-icons-wrapper {
    margin-left: 5px;
    display: none; }
  .non-standard-tickets-table-headers .column--sort-option {
    color: #0077D7; }
    .non-standard-tickets-table-headers .column--sort-option .sort-icons-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .non-standard-tickets-table-headers .column--sort-option .sort-icons-wrapper .hide-icon {
        visibility: hidden; }
      .non-standard-tickets-table-headers .column--sort-option .sort-icons-wrapper > * {
        margin: 2px; }

.non-standard-tickets-table-row {
  display: -webkit-flex;
  display: flex;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px; }
  .non-standard-tickets-table-row:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px; }
  .non-standard-tickets-table-row .column {
    width: 100px;
    margin: 0 5px; }
    .non-standard-tickets-table-row .column input {
      width: 100%; }


.option-pill {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  padding: 0 7px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .option-pill .text {
    margin-right: 8px; }
  .option-pill .icon-wrapper {
    width: 10px; }
    .option-pill .icon-wrapper .plus-icon-svg {
      width: 10px;
      height: 10px; }
    .option-pill .icon-wrapper .x-icon-svg {
      width: 8px;
      height: 8px; }
  .option-pill:hover {
    color: #0077D7; }
    .option-pill:hover .plus-icon-svg path {
      stroke: #0077d7; }
  .option-pill.selected {
    background-color: #C3E2F9;
    color: #0077d7; }
    .option-pill.selected .plus-icon-svg {
      stroke: #0077D7; }


.table-row {
  display: -webkit-flex;
  display: flex; }
  .table-row > div {
    width: 200px; }
  .table-row.loading svg {
    height: 22px;
    width: 22px; }

