.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .actions {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Home .actions .header {
  font-size: 18px;
  margin-bottom: 20px;
}

.Home .actions .actions-list {
  display: flex;
  justify-content: center;
}

.Home .actions .actions-list .button {
  margin-right: 15px;
}

.Home .actions .actions-list .button:last-child {
  margin-right: 0px;
}
