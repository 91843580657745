.non-standard-ticket-management {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .table-header {
    border-bottom: 2px solid #c7c7c7;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;

    .option-section, .dates, .search-section {
      margin-bottom: 5px;
    }
    
    .option-section {
      .option-pills {
        display: flex;
        width: 600px;
        flex-wrap: wrap;
  
        .option-pill {
          margin: 3px 5px;
        }
      }
    }
  
    .dates {
      display: flex;
    }
  
    .search-section {
      .search-terms {
        display: flex;
        width: 600px;
        flex-wrap: wrap;
    
        .option-pill {
          margin: 3px 5px;
        }
      }
    }

    .button {
      position: absolute;
      right: 50px;
      bottom: 15px;
    }
  }

  .table-information {
    flex-grow: 0;
    overflow: auto;
    margin-bottom: 25px;
  }
  
}