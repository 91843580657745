.waiting-for-response {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.skyvia-check {
  .inputs-row {
    margin-top: 20px;
    height: 30px;

    .input {
      height: 100%;
      margin-right: 15px;
      border: 2px solid #c4c4c4;
      border-radius: 3px;

      &--error {
        border-color: red;
      }
    }
  }
}

.mark-complete-finished {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    margin-bottom: 15px;
  }
}
