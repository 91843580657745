.non-standard-tickets-table-headers {
  .column {
    cursor: pointer;
    display: flex;
    color: #888888;

    .sort-icons-wrapper {
      margin-left: 5px;
      display: none;
    }

    &--sort-option {
      color: #0077D7;

      .sort-icons-wrapper {
        display: flex;
        flex-direction: column;

        .hide-icon {
          visibility: hidden;
        }

        > * {
          margin: 2px;
        }
      }
    }
  }
}